jQuery(($) => {
    const $galleryBoard = $('.gallery__board');

    const $gridActions = $('.gallery__arrow');

    let gridScrollInterval;

    function scrollGrid(direction) {
        $galleryBoard.animate(
            { scrollLeft: direction === 'left' ? '-=236' : '+=236' },
            336,
            'linear',
        );
    }

    $gridActions.on('touchstart mousedown', (e) => {
        if (e.type === 'mousedown' && e.which !== 1) {
            return;
        }

        e.preventDefault(); 

        const $action = $(e.currentTarget);
        const direction = $action.data('grid-direction');

        if (gridScrollInterval !== undefined) {
            clearInterval(gridScrollInterval);
        } 

        scrollGrid(direction);
        gridScrollInterval = setInterval(() => scrollGrid(direction), 290);
    });

    $gridActions.on('touchend mouseup', (e) => {
        if (e.type === 'mouseup' && e.which !== 1) {
            return;
        }

        e.preventDefault();

        if (gridScrollInterval !== undefined) {
            clearInterval(gridScrollInterval);
            $galleryBoard.stop(true, false);
            gridScrollInterval = undefined;
        }
    });
});
